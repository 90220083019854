import { datadogLogs } from '@datadog/browser-logs';
const { logger } = datadogLogs;

const configValidation = (key: string, envConfig: string | undefined) => {
  if (envConfig === undefined) {
    const error = `[ERROR] Missing required configuration ${key}`;
    console.error(error);
    logger.error(error);
    return '';
  }
  return envConfig;
};

// All required .env configurations should be added to this file
export const API_URL = configValidation('API_URL', process.env['API_URL']);
export const STRIPE_PUBLISHABLE_KEY = configValidation(
  'STRIPE_PUBLISHABLE_KEY',
  process.env['STRIPE_PUBLISHABLE_KEY']
);
export const TRACKING_URL = configValidation('TRACKING_URL', process.env['TRACKING_URL']);
export const HAS_DATADOG = configValidation('HAS_DATADOG', process.env['HAS_DATADOG']);
export const ENVIRONMENT = configValidation('ENVIRONMENT', process.env['ENVIRONMENT']);
export const SEGMENT_API_KEY = configValidation('SEGMENT_API_KEY', process.env['SEGMENT_API_KEY']);
export const BEST_SELLER_RECIPE_IDS = configValidation(
  'BEST_SELLER_RECIPE_IDS',
  process.env['BEST_SELLER_RECIPE_IDS']
);
export const SHOW_SHOP_TAB = process.env['SHOW_SHOP_TAB'];
export const IS_UNDER_MAINTENANCE = configValidation(
  'IS_UNDER_MAINTENANCE',
  process.env['IS_UNDER_MAINTENANCE']
);
