import { addonSeqNoCompare } from './orders';

import {
  Account,
  Pet,
  PetPlan,
  PetPlanProductStatus,
  PetPlanStatus,
  Recipe,
  RecipeFull,
  RecipeType,
} from '@/api';

export const getPetPlanMeals = (petPlan: PetPlan) =>
  petPlan.products.filter((product) =>
    [RecipeType.FRESH, RecipeType.UNKIBBLE].includes(product.recipes[0].type)
  );

export const getPetPlanAddons = (
  petPlan: PetPlan,
  recipeType: RecipeType,
  { excludeCancelled }: { excludeCancelled?: boolean } = {}
) => {
  const addonsOfType = petPlan.products
    .filter((product) => recipeType === product.recipes[0].type)
    .sort(addonSeqNoCompare);

  if (excludeCancelled) {
    return addonsOfType.filter((product) => product.status !== PetPlanProductStatus.CANCELLED);
  } else {
    return addonsOfType;
  }
};

export const getActivePlans = (account: Account) =>
  account.petplans.filter((plan) => plan.status === PetPlanStatus.ACTIVE);

export const getFirstPetWithAddon = (account: Account, recipeId: Recipe['id']) => {
  const activePlans = getActivePlans(account);
  const firstPlanWithAddon = activePlans?.find((petPlan) =>
    petPlan.products.some((product) => product.recipes.some((recipe) => recipe.id === recipeId))
  );
  return firstPlanWithAddon?.pet;
};

export const getAddOnQuantitiesByPet = (
  addOnRecipes: RecipeFull[],
  account: Account,
  petIds?: Pet['id'][]
) => {
  if (!account) {
    return;
  }

  const petPlanProductsToCheck = account.pets
    .filter((pet) => {
      // defaults to summing quantity across all pets
      return petIds?.includes(pet.id) || true;
    })
    .flatMap((pet) => pet.pet_plan.products);

  const addOnQuantityObj: { [key: string]: number } = {};
  addOnRecipes?.forEach((addOnRecipe) => {
    let recipeQuantity = 0;
    petPlanProductsToCheck.forEach((ppp) => {
      if (ppp.recipes.every((recipe) => recipe.id === addOnRecipe.id)) {
        // Only accommodates single recipe add-ons
        recipeQuantity += ppp.quantity;
      }
    });
    addOnQuantityObj[addOnRecipe.id] = recipeQuantity;
  });
  return addOnQuantityObj;
};

export const accountHasMultipleDogs = (account: Account) => account.pets.length > 1;
