import { Heading, Image, Stack } from 'native-base';

import { ASSETS_URL } from '@/constants';

const MaintenanceScreen = () => {
  return (
    <Stack justifyContent="center" alignItems="center" paddingTop={{ base: '16px', md: '36px' }}>
      <Stack
        paddingBottom={{ base: '64px', md: '80px' }}
        h={{ base: '20px', lg: '' }}
        justifyContent="center"
        alignItems="center"
      >
        <Image
          width={{ base: '117px', lg: '282px' }}
          height={{ base: '20px', lg: '64px' }}
          source={{
            uri: `${ASSETS_URL}/logo.svg`,
          }}
          alt="Spot & Tango Logo"
          resizeMode="contain"
        />
      </Stack>
      <Heading
        fontSize={{ base: '24px', md: '32px' }}
        fontFamily="primary"
        fontWeight="bold"
        textAlign="center"
      >
        Thanks for your patience, we are learning a new trick and will be right back!
      </Heading>
      <Stack py={{ base: '16px', md: '36px' }}>
        <Image
          width={{ base: '200px', md: '250px' }}
          height={{ base: '204px', md: '257px' }}
          source={{ uri: `${ASSETS_URL}/pictos/Illustrations/page-not-found.svg` }}
          alt="Spot & Tango Logo"
          resizeMode="contain"
        />
      </Stack>
    </Stack>
  );
};

export default MaintenanceScreen;
