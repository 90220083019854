import { useNavigation } from '@react-navigation/native';
import type { StackNavigationProp } from '@react-navigation/stack';
import {
  VStack,
  HStack,
  IconButton,
  IScrollViewProps,
  IStackProps,
  Heading,
  useTheme,
  Text,
  Stack,
  IHeadingProps,
  ITextProps,
} from 'native-base';
import React, { useMemo, useRef, useState } from 'react';
import { NativeScrollEvent, NativeSyntheticEvent, ScrollView } from 'react-native';
import { ArrowSmallLeftIcon, ArrowSmallRightIcon } from 'react-native-heroicons/solid';

import { DogSelectModal } from '../DogSelectModal';
import { AddOnCarouselSkeleton } from './AddOnCarouselSkeleton';

import { useAddonRecipes, useUpdateAddon } from '@/api';
import { RecipeFull, RecipeType } from '@/api/types';
import { CarouselItem, PDPModal, ToastType, displayToast } from '@/components/Elements';
import { ADDONS_CONFIGURATION, ASSETS_URL, AddonsType } from '@/constants';
import { useAccount, useAuth, useSessionQuery } from '@/hooks';
import segment from '@/segment';
import { ProtectedStackParamList } from '@/types';
import { capitalize, getActivePlans, getAddOnQuantitiesByPet, getFirstPetWithAddon } from '@/utils';

const images = `${ASSETS_URL}/pictos/`;

interface AddOnCarouselProps extends IStackProps {
  availableAddons?: RecipeFull[];
  onAdd?: (props?: any) => void;
  title?: IHeadingProps['children'];
  description?: ITextProps['children'];
  scrollArrows?: boolean;
  scrollViewProps?: IScrollViewProps;
  petIdsForQuantityTotal?: string[];
}

export const AddOnCarousel = ({
  availableAddons,
  onAdd,
  title = 'S&T Shop – Bestsellers',
  description,
  scrollArrows = true,
  scrollViewProps,
  petIdsForQuantityTotal,
  ...props
}: AddOnCarouselProps) => {
  const [showPDPModal, setShowPDPModal] = useState(false);
  const [showDogSelectModal, setShowDogSelectModal] = useState(false);
  const scrollRef = useRef<ScrollView>(null);
  const [offsetX, setOffsetX] = useState(0);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [pdpRecipe, setPdpRecipe] = useState<RecipeFull | null>(null);

  const theme = useTheme();
  const { refetchUser } = useAuth();
  const account = useAccount();
  const navigation = useNavigation<StackNavigationProp<ProtectedStackParamList>>();
  const { mutateAsync: addAddon, isLoading: isAddAddonsLoading } = useUpdateAddon();
  const { data: allAddons, isLoading: isRecipesLoading } = useAddonRecipes(account?.id);
  const { session } = useSessionQuery();

  const activePets = useMemo(() => {
    return getActivePlans(account).map((petPlan) => petPlan.pet);
  }, [account]);

  const addOnQuantities = useMemo(() => {
    if (!account || !availableAddons?.length || !petIdsForQuantityTotal) {
      return;
    }
    return getAddOnQuantitiesByPet(availableAddons, account, petIdsForQuantityTotal);
  }, [account, availableAddons, petIdsForQuantityTotal]);

  const handleAdd = async (code: string | undefined, petId: string) => {
    if (!code) return;
    const petData = account?.pets.find((pet) => pet.id === petId);
    if (petData) {
      const snack = allAddons?.find((recipe: RecipeFull) => recipe.default_product?.code === code);
      try {
        await addAddon({
          petPlanId: petData.pet_plan.id,
          products: [{ code, quantity: 1, recurring: true }],
        });

        await refetchUser();
        navigation.setParams({ petId: petData.id });
        displayToast({
          message: `${snack?.name} have been added to ${petData.name}'s plan.`,
          type: ToastType.Success,
        });
      } catch (err) {
        displayToast({
          message: `An error occurred while adding ${snack?.name} to ${petData.name}'s plan.`,
          type: ToastType.Error,
        });
        console.error(err);
      }
    }
  };

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const posX = e.nativeEvent.contentOffset.x;
    setOffsetX(posX);
    if (posX > 0 && prevDisabled) {
      setPrevDisabled(false);
    } else if (posX === 0 && !prevDisabled) {
      setPrevDisabled(true);
    }
    const scrollableWidth = e.nativeEvent.contentSize.width - e.nativeEvent.layoutMeasurement.width;
    if (posX + 1 >= scrollableWidth && !nextDisabled) {
      setNextDisabled(true);
    } else if (posX + 1 < scrollableWidth && nextDisabled) {
      setNextDisabled(false);
    }
  };

  const onPressAddOn = (recipe: RecipeFull) => {
    segment.trackEvent('Click Add-On Product Details', {
      email: account.email,
      product_code: recipe.default_product.code,
      pet_name: null, // kept for parity with old Segment events
      account_id: account.id,
      session_id: session?.id,
    });

    setPdpRecipe(recipe);
    setShowPDPModal(true);
  };

  if (!availableAddons?.length) {
    return null;
  }

  if (isRecipesLoading) {
    return <AddOnCarouselSkeleton numItems={4} />;
  }

  return (
    <VStack
      space={{ base: 4, lg: 6 }}
      w={{ base: '100%', lg: scrollArrows ? '987px' : '840px' }}
      alignSelf="center"
    >
      <Stack
        space={{ base: 1, lg: 2 }}
        w={{ base: '100%', lg: '824px' }}
        justifyContent="center"
        alignItems="left"
        px={{ base: 0, lg: '88px' }}
      >
        <Heading size="bodyMlToTitleSm" fontWeight="bold">
          {title}
        </Heading>
        {description ? (
          <Text size="bodySmToMd" fontWeight="bold" fontFamily="secondary">
            {description}
          </Text>
        ) : null}
      </Stack>
      <HStack alignItems="center" position="relative" space={{ base: '0px', lg: '48px' }}>
        {scrollArrows ? (
          <IconButton
            disabled={prevDisabled}
            display={{ base: 'none', lg: 'flex' }}
            w="fit-content"
            p="4px"
            onPress={() => {
              if (scrollRef?.current) {
                scrollRef.current.scrollTo({ x: offsetX - 330 });
              }
            }}
            icon={
              <ArrowSmallLeftIcon
                color={prevDisabled ? theme.colors.sntGrey.primary : theme.colors.black}
                size="24px"
              />
            }
          />
        ) : null}
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          ref={scrollRef}
          onScroll={handleScroll}
          scrollEventThrottle={16}
          overflow="hidden"
          {...scrollViewProps}
        >
          <HStack
            w="100%"
            alignSelf="center"
            justifyContent="start"
            alignItems="start"
            space={4}
            pl={0}
            {...props}
          >
            {availableAddons?.map((recipe, i) => {
              const { unit, description } = ADDONS_CONFIGURATION[recipe.type as AddonsType];
              const quantity = addOnQuantities ? addOnQuantities[recipe.id] : undefined;
              const firstPetWithRecipe = getFirstPetWithAddon(account, recipe.id);
              const onAdd = () => {
                setPdpRecipe(recipe);
                if (!account || !activePets?.length) return;

                if (activePets.length > 1) {
                  setShowDogSelectModal(true);
                  segment.trackEvent('Add-On Subscription Initiated', {
                    account_id: account.id,
                    email: account.email,
                    product_code: recipe.default_product.code,
                  });
                } else if (activePets?.length) {
                  handleAdd(recipe.default_product?.code, activePets[0]);
                }
              };

              return (
                <CarouselItem
                  unit={unit}
                  key={recipe.id + i.toString()}
                  onPressImage={() => {
                    onPressAddOn(recipe);
                  }}
                  title={recipe.name}
                  description={description.forCarousel}
                  price={recipe.default_product.price.price}
                  quantity={quantity}
                  image={`${images}${capitalize(recipe.type)}/${recipe.id}.png`}
                  isLongPressAvailable
                  hoverStateImage={`${images}${capitalize(recipe.type)}/${recipe.id}-HS.png`}
                  value={0}
                  isLoading={isRecipesLoading || isAddAddonsLoading}
                  badgeMessage={capitalize(recipe.type)}
                  onAdd={onAdd}
                  onEditQuantity={() =>
                    navigation.push('Dogs', { petId: firstPetWithRecipe, scrollToTop: true })
                  }
                />
              );
            })}
          </HStack>
        </ScrollView>
        {scrollArrows ? (
          <IconButton
            disabled={nextDisabled}
            variant="icon"
            display={{ base: 'none', lg: 'flex' }}
            w="fit-content"
            p="4px"
            onPress={() => {
              if (scrollRef?.current) {
                scrollRef.current.scrollTo({ x: offsetX + 330 });
              }
            }}
            icon={
              <ArrowSmallRightIcon
                color={nextDisabled ? theme.colors.sntGrey.primary : theme.colors.black}
                size="24px"
              />
            }
          />
        ) : null}
      </HStack>

      {pdpRecipe ? (
        <PDPModal
          recipe={pdpRecipe}
          buttonText="ADD TO PLAN"
          showCTA
          prices={[pdpRecipe.default_product?.price.price]}
          pricingUnits={[pdpRecipe.type === RecipeType.SNACK ? 'bag' : 'pack']}
          updateState={() => {
            if (account?.pets.length > 1) {
              setShowDogSelectModal(true);
            } else {
              handleAdd(pdpRecipe.default_product?.code, account?.pets[0].id);
            }
          }}
          isOpen={showPDPModal}
          onClose={() => setShowPDPModal(false)}
        />
      ) : null}
      {pdpRecipe ? (
        <DogSelectModal
          isOpen={showDogSelectModal}
          onClose={() => setShowDogSelectModal(false)}
          onSuccess={() => setShowDogSelectModal(false)}
          recipe={pdpRecipe}
          petIds={activePets}
          onConfirm={addAddon}
          isLoading={isRecipesLoading || isAddAddonsLoading}
          showQuantityByPet={false}
        />
      ) : null}
    </VStack>
  );
};
