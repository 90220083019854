import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import { Stack, Text, Heading, useBreakpointValue, HStack, Box, VStack, View } from 'native-base';
import { useEffect, useState } from 'react';

// all types in @/types? - cleaner that way imo.
import { Cart, useCreateSessionSurveyResponse } from '@/api';
// refactored in the future to be from `@/components` ?
import { PostCheckoutSurveyModal } from '@/components';
import { Image } from '@/components/Elements';
import {
  CreatePasswordForm,
  CreatePasswordFormValues,
} from '@/components/forms/CreatePasswordForm';
// use `ASSETS_URL` by default with a custom image component,
// so it doesnt have to be imported everywhere an image is being used?
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames, ASSETS_URL } from '@/constants';
import { useAuth, useIsMobile, useSessionQuery, useUnbounce } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { queryClient } from '@/lib/react-query';
import segment from '@/segment';
import { FunnelScreenProps, FunnelSession } from '@/types';
import { getEstimatedArrivalDate } from '@/utils';

const images = `${ASSETS_URL}/pictos/`;

export default function PostCheckout({ navigation, route }: FunnelScreenProps<'PostCheckout'>) {
  const { login } = useAuth();
  const isMobile = useIsMobile();
  const [_, setCart] = useState<Cart['cart_preview']>();
  const [email, setEmail] = useState<string>();
  const [openSurvey, setOpenSurvey] = useState(false);
  const chargeDate = route?.params?.chargeDate && new Date(route.params.chargeDate);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitingSurvey, setIsSubmitingSurvey] = useState<boolean>(false);

  const headingFontWeight = useBreakpointValue({ base: 'bold', lg: 'medium' });
  const subheadingFontWeight = useBreakpointValue({ base: 'normal', lg: 'medium' });

  const { updatePassword, session, mutateUpdateSession } = useSessionQuery();
  const funnelErrorHandler = useFunnelErrorHandler();
  useUnbounce();
  useEffect(() => {
    const getCart = async () => {
      const cart = queryClient.getQueryData<Cart>('cart')?.cart_preview;
      const { email } = queryClient.getQueryData<FunnelSession>('session') as FunnelSession;
      setEmail(email);
      if (cart) setCart(cart);
    };

    getCart();
  }, []);

  useEffect(() => {
    if (session && !session.has_completed_survey) {
      setOpenSurvey(true);
    }
  }, [session?.has_completed_survey]);

  // Should set the password
  // then log the user in
  // then redirect to the portal
  const handleSubmit = async (data: CreatePasswordFormValues) => {
    try {
      setIsLoading(true);
      await updatePassword(data);
      await login({ email, password: data.password });
      setIsLoading(false);
      navigation.navigate(FunnelScreenNames.PORTAL);
    } catch (error) {
      setIsLoading(false);
      funnelErrorHandler(error, 'Set Password');
    }
  };

  const { mutateAsync: createSurveyResponse } = useCreateSessionSurveyResponse();

  const handleSubmitSurvey = async ({
    selectedValue,
    otherText,
  }: {
    selectedValue: string;
    otherText: string;
  }) => {
    const prevSession = session as FunnelSession;
    await mutateUpdateSession({
      data: {
        has_completed_survey: true,
      },
    });

    await createSurveyResponse({
      data: { primary_answer: selectedValue, customer_comments: otherText },
      id: prevSession.id,
    });

    setIsSubmitingSurvey(false);

    segment.trackEvent('Survey Completed', {
      email: session?.email,
      feedback_where: selectedValue,
      feedback_why: null,
      account_id: session?.account,
      session_id: session?.id,
    });
  };

  if (!session?.pets || !chargeDate) {
    return null;
  }

  const dogs = session?.pets;
  const recipientName = dogs.length > 1 ? 'Your' : `${dogs?.[0].name}'s`;
  const estArrivalStartDate = dayjs(
    getEstimatedArrivalDate(chargeDate.toISOString(), 'YYYY-MM-DD')
  );
  const estArrivalStart = estArrivalStartDate.format('MMMM D');
  const estArrivalEndDate = dayjs(
    getEstimatedArrivalDate(addDays(chargeDate, 3).toISOString(), 'YYYY-MM-DD')
  );
  const estArrivalEnd = estArrivalEndDate.format(
    estArrivalEndDate.month() === estArrivalStartDate.month() ? 'D' : 'MMM D'
  );

  const defaultDescription = (
    <>
      <View display="inline-block">
        <Text
          textAlign="center"
          fontFamily="secondary"
          fontWeight={subheadingFontWeight}
          size="bodySmToMd"
        >
          {recipientName} box is estimated to arrive between{' '}
        </Text>
        <Text fontFamily="secondary" size="bodySmToMd" fontWeight="bold">
          {estArrivalStart} - {estArrivalEnd}.
        </Text>
      </View>
      <View display="inline-block">
        <Text
          textAlign="center"
          fontFamily="secondary"
          fontWeight={subheadingFontWeight}
          size="bodySmToMd"
        >
          An email is being sent to{' '}
        </Text>
        <Text fontFamily="secondary" size="bodySmToMd" fontWeight="bold">
          {email}{' '}
        </Text>
        <Text
          textAlign="center"
          fontFamily="secondary"
          fontWeight={subheadingFontWeight}
          size="bodySmToMd"
        >
          with all the details.
        </Text>
      </View>
    </>
  );
  const mobileDescription = (
    <View display="inline-block" textAlign="center">
      <Text
        textAlign="center"
        fontFamily="secondary"
        fontWeight={subheadingFontWeight}
        size="bodySmToMd"
      >
        {recipientName} box is estimated to arrive between{' '}
      </Text>
      <Text fontFamily="secondary" size="bodySmToMd" fontWeight="bold">
        {estArrivalStart} - {estArrivalEnd}.{' '}
      </Text>
      <Text
        textAlign="center"
        fontFamily="secondary"
        fontWeight={subheadingFontWeight}
        size="bodySmToMd"
      >
        An email is being sent to{' '}
      </Text>
      <Text fontFamily="secondary" size="bodySmToMd" fontWeight="bold">
        {email}{' '}
      </Text>
      <Text
        textAlign="center"
        fontFamily="secondary"
        fontWeight={subheadingFontWeight}
        size="bodySmToMd"
      >
        with all the details.
      </Text>
    </View>
  );

  const descriptionBlock = isMobile ? mobileDescription : defaultDescription;

  return (
    <FunnelWrapper>
      <PostCheckoutSurveyModal
        isOpen={openSurvey}
        onExit={() => setOpenSurvey(false)}
        onClose={() => setOpenSurvey(false)}
        isLoading={isSubmitingSurvey}
        onSubmit={(val) => {
          setIsSubmitingSurvey(true);
          handleSubmitSurvey(val);
          setOpenSurvey(false);
        }}
      />
      <Stack w="100%">
        <Stack space={9}>
          <Stack alignItems="center">
            <Heading size="titleSmToMd" fontWeight="bold" mb="15px">
              Order Confirmed!
            </Heading>
            {descriptionBlock}
          </Stack>
          <Stack
            flexDirection={{ base: 'column', lg: 'row' }}
            mt={{ base: '36px', md: '48px' }}
            mb="36px"
          >
            <Stack flex={1} mr={{ base: 0, lg: '120px' }}>
              <Stack maxW={{ base: '100%', md: '472px' }}>
                <CreatePasswordForm
                  email={email}
                  heading="Finish creating your account"
                  subHeading="Manage your plan by creating an account"
                  buttonText="Create & View Account"
                  onSubmit={handleSubmit}
                  isLoading={isLoading}
                  onError={(error) => console.log(error)}
                />
              </Stack>
            </Stack>
            <Stack flex={1}>
              <Stack
                bgColor="sntBlue.primary75"
                alignItems="center"
                py={{ base: '16px', md: '24px' }}
                maxW={{ base: '100%', md: '459px' }}
                mt={{ base: '36px', md: 0 }}
              >
                <Text
                  size="bodyMlToTitleSm"
                  fontWeight={headingFontWeight}
                  mb={{ base: '16px', md: '24px' }}
                >
                  Things you can do here
                </Text>
                <VStack justifyContent="center" space={{ base: '16px', md: '24px' }}>
                  <PortalFeaturesListItem
                    text="Review upcoming orders"
                    imgSrc="CardIcons/calendar.png"
                  />
                  <PortalFeaturesListItem
                    text="Customize your dog's meal plan"
                    imgSrc="CardIcons/french-bulldog.png"
                  />
                  <PortalFeaturesListItem text="Track shipments" imgSrc="Icons/shipping.png" />
                  <PortalFeaturesListItem
                    text="Shop additional products"
                    imgSrc="CardIcons/fresh-ingredients.png"
                  />
                  <PortalFeaturesListItem
                    text="Refer your friends"
                    imgSrc="CardIcons/paper-plane.png"
                  />
                </VStack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </FunnelWrapper>
  );
}

interface PortalFeaturesListItemProps {
  imgSrc: string;
  text: string;
}

const PortalFeaturesListItem = ({ imgSrc, text }: PortalFeaturesListItemProps) => (
  <HStack alignItems="center">
    <Box pr="10px">
      <Image w="46px" h="46px" alt={imgSrc} src={`${images}${imgSrc}`} resizeMode="contain" />
    </Box>
    <Text fontFamily="secondary" fontWeight="medium" size="bodySmToMd">
      {text}
    </Text>
  </HStack>
);
