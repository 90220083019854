import { createNativeStackNavigator, NativeStackHeaderProps } from '@react-navigation/native-stack';

import { OrdersTab, RescheduleDelivery, DogsTab, OrderDetailPage, ShopTab } from '../';
import { DogSelectFoodType, DogSelectRecipe } from '../DogsAndPlans';
import { AccountNav } from './AccountNav';
import { AccountTab } from './AccountTab';
import { AddCreditCard, EditCreditCard, PaymentMethodList } from './Billing';
import { ChangePassword } from './ChangePassword';
import { FormScreenHeader } from './FormScreenHeader';
import { HomeTab } from './HomeTab';
import {
  AdjustPortionSize,
  ChangePlanStatus,
  ManageActivePlans,
  ManageInactivePlans,
  StopPlanSubSurvey,
  StopPlanSurvey,
  ReactivatePlan,
  ReactivatePlanRescheduleDelivery,
  SwapToTopper,
  SnoozePlan,
  TryNewRecipe,
} from './ManageDogPlans';
import { RushADelivery } from './ManageDogPlans/ChangePlanStatus/RushADelivery';
import { ChangeRecipe } from './ManageDogPlans/ChangeRecipe';
import { NotificationPreferences } from './Notifications';
import { PersonalInformation } from './PersonalInformation';
import { AddNewAddress, AddressList, EditAddress } from './Shipping';

import { useAuth } from '@/hooks';
import { ProtectedStackParamList } from '@/types';

export const AccountStack = () => {
  const Stack = createNativeStackNavigator<ProtectedStackParamList>();

  const { refetchUser } = useAuth();

  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{ headerShown: true, header: (props) => <AccountNav {...props} /> }}
    >
      <Stack.Screen
        name="Home"
        options={{ headerShown: false }}
        component={HomeTab}
        listeners={{ focus: () => refetchUser() }}
      />
      <Stack.Screen
        name="Orders"
        options={{ headerShown: false }}
        component={OrdersTab}
        listeners={{ focus: () => refetchUser() }}
      />
      <Stack.Screen
        name="Dogs"
        options={{ headerShown: false }}
        component={DogsTab}
        listeners={{ focus: () => refetchUser() }}
      />
      {/* TO DO: Remove this check once Shop Tab is launched */}
      {process.env.SHOW_SHOP_TAB === 'true' && (
        <Stack.Screen name="Shop" options={{ headerShown: false }} component={ShopTab} />
      )}
      <Stack.Group screenOptions={{ header: FormScreenHeader }}>
        <Stack.Screen name="DogSelectFoodType" component={DogSelectFoodType} />
        <Stack.Screen name="DogSelectRecipe" component={DogSelectRecipe} />
      </Stack.Group>
      <Stack.Screen
        name="Account"
        options={{ headerShown: false }}
        component={AccountTab}
        listeners={{ focus: () => refetchUser() }}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Personal Information',
        }}
        name="PersonalInformation"
        component={PersonalInformation}
      />
      {/* Shipping Address screens */}
      <Stack.Group screenOptions={{ header: FormScreenHeader }}>
        <Stack.Screen
          name="AddressList"
          component={AddressList}
          options={{ title: 'Change Shipping Address' }}
        />
        <Stack.Screen
          name="AddNewAddress"
          component={AddNewAddress}
          options={{ title: 'Add New Address' }}
        />
        <Stack.Screen name="EditAddress" component={EditAddress} />
      </Stack.Group>
      {/* Billing screens */}
      <Stack.Group screenOptions={{ header: FormScreenHeader }}>
        <Stack.Screen
          name="PaymentMethodList"
          component={PaymentMethodList}
          options={{ title: 'Change Payment Method' }}
        />
        <Stack.Screen
          name="AddCreditCard"
          component={AddCreditCard}
          options={{ title: 'Credit Card' }}
        />
        <Stack.Screen
          name="EditCreditCard"
          component={EditCreditCard}
          options={{ title: 'Credit Card' }}
        />
      </Stack.Group>

      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Manage or Cancel Plans',
        }}
        name="ManageActivePlans"
        component={ManageActivePlans}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Review and Confirm',
        }}
        name="ReactivatePlan"
        component={ReactivatePlan}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
        }}
        name="ReactivatePlanRescheduleDelivery"
        component={ReactivatePlanRescheduleDelivery}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Manage Inactive Plans',
        }}
        name="ManageInactivePlans"
        component={ManageInactivePlans}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
        }}
        name="AdjustPortionSize"
        component={AdjustPortionSize}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
        }}
        name="ChangeRecipe"
        component={ChangeRecipe}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: '',
        }}
        name="ChangePlanStatus"
        component={ChangePlanStatus}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
        }}
        name="StopPlanSurvey"
        component={StopPlanSurvey}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: `Let's Get Specific`,
        }}
        name="StopPlanSubSurvey"
        component={StopPlanSubSurvey}
      />
      <Stack.Screen
        options={{
          header: (props: NativeStackHeaderProps) => (
            <FormScreenHeader
              stackProps={{ pb: { base: 2, md: 12 }, pt: { base: 6, md: 12 } }}
              {...props}
            />
          ),
          title: 'Let Us Help With Cost',
        }}
        name="SwapToTopper"
        component={SwapToTopper}
      />
      <Stack.Screen
        options={{
          header: (props: NativeStackHeaderProps) => (
            <FormScreenHeader
              stackProps={{ pb: { base: 2, md: 4 }, pt: { base: 6, md: 12 } }}
              {...props}
            />
          ),
          title: 'Take a Break',
        }}
        name="SnoozePlan"
        component={SnoozePlan}
      />
      <Stack.Screen
        options={{
          header: (props: NativeStackHeaderProps) => (
            <FormScreenHeader
              stackProps={{ pb: { base: 2, md: 4 }, pt: { base: 6, md: 12 } }}
              {...props}
            />
          ),
          title: 'Rush a Delivery',
        }}
        name="RushADelivery"
        component={RushADelivery}
      />
      <Stack.Screen
        options={{
          header: (props: NativeStackHeaderProps) => (
            <FormScreenHeader
              stackProps={{ pb: { base: 2, md: 4 }, pt: { base: 6, md: 12 } }}
              {...props}
            />
          ),
          title: 'Try a New Recipe — 30% OFF!',
        }}
        name="TryNewRecipe"
        component={TryNewRecipe}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Notification Preferences',
        }}
        name="NotificationPreferences"
        component={NotificationPreferences}
      />
      <Stack.Screen
        options={{
          header: FormScreenHeader,
          title: 'Change Password',
        }}
        name="ChangePassword"
        component={ChangePassword}
      />
      <Stack.Screen
        name="RescheduleDelivery"
        component={RescheduleDelivery}
        options={{
          header: FormScreenHeader,
          title: 'Reschedule',
        }}
      />
      <Stack.Screen
        name="OrderDetailPage"
        component={OrderDetailPage}
        options={{
          header: FormScreenHeader,
          title: 'Your Order',
        }}
      />
    </Stack.Navigator>
  );
};
