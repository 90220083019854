import { datadogLogs } from '@datadog/browser-logs';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Heading, HStack, IStackProps, Text, Stack, VStack } from 'native-base';
import { forwardRef, useMemo, useState } from 'react';

import { DogSelectModal } from '../DogSelectModal';

import { useAddonRecipes, useUpdateAddon } from '@/api';
import { RecipeFull, RecipeType } from '@/api/types';
import {
  CarouselItem,
  displayToast,
  Image,
  PDPModal,
  ToastType,
  VToHStack,
} from '@/components/Elements';
import { ADDONS_CONFIGURATION, AddonsType, ASSETS_URL } from '@/constants';
import { useAccount, useAuth, useIsMobile } from '@/hooks';
import { ProtectedStackParamList } from '@/types';
import { capitalize, getActivePlans, getAddOnQuantitiesByPet, getFirstPetWithAddon } from '@/utils';

const { logger } = datadogLogs;
export interface ProductSectionProps extends IStackProps {
  sectionData: {
    recipeType: RecipeType;
    title: string;
    bannerUri: string;
    iconUri: string;
    subheading: string;
  };
}

const images = `${ASSETS_URL}/pictos/`;

export const ProductSection = forwardRef<HTMLDivElement, ProductSectionProps>(
  ({ sectionData: { recipeType, title, bannerUri, iconUri, subheading }, ...props }, ref) => {
    const [modalRecipe, setModalRecipe] = useState<RecipeFull | null>(null);
    const [isPDPOpen, setIsPDPOpen] = useState(false);
    const [isDogSelectOpen, setIsDogSelectOpen] = useState(false);
    const isMobile = useIsMobile();
    const SubHeading = () => (
      <Text fontFamily="secondary" size="bodyMdToLg" maxW="305px">
        {subheading}
      </Text>
    );
    const account = useAccount();
    const { data: availableAddons, isLoading: isRecipesLoading } = useAddonRecipes(account?.id);
    const activePets = useMemo(() => {
      return getActivePlans(account).map((petPlan) => petPlan.pet);
    }, [account]);
    const { mutateAsync: addAddon, isLoading: isAddAddonsLoading } = useUpdateAddon();
    const { refetchUser } = useAuth();

    const addOnQuantities = useMemo(() => {
      if (!account || !availableAddons?.length || !activePets) {
        return;
      }
      return getAddOnQuantitiesByPet(availableAddons, account, activePets);
    }, [account, availableAddons, activePets]);
    const navigation = useNavigation<StackNavigationProp<ProtectedStackParamList>>();
    const onPressAddOn = (recipe: RecipeFull) => {
      setModalRecipe(recipe);
      setIsPDPOpen(true);
    };
    const handleAdd = async (code: string | undefined, petId: string, quantity: number) => {
      if (!code) return;
      const petData = account?.pets.find((pet) => pet.id === petId);
      if (petData) {
        const snack = availableAddons?.find((recipe) => recipe.default_product?.code === code);
        try {
          await addAddon({
            petPlanId: petData.pet_plan.id,
            products: [{ code, quantity: quantity + 1, recurring: true }],
          });

          await refetchUser();
          displayToast({
            message: `${snack?.name} have been added to ${petData.name}'s plan.`,
            type: ToastType.Success,
          });
        } catch (err) {
          displayToast({
            message: `An error occurred while adding ${snack?.name} to ${petData.name}'s plan.`,
            type: ToastType.Error,
          });
          logger.error(`Error adding ${snack?.name} to ${petData.name}'s plan: ${err}`);
        }
      }
    };
    return (
      <VStack space={{ base: 4, lg: 6 }} w={{ base: '100%' }} alignSelf="center">
        <Stack ref={ref} {...props} space={{ base: 9, lg: 12 }} w="100%" h="100%">
          <VToHStack justifyContent="start" space={{ base: 4, lg: 6 }}>
            <Stack>
              <HStack space={{ base: 1, lg: 2 }} alignItems="center">
                <Heading size="titleMdToMl" fontWeight="bold">
                  {title.toLocaleUpperCase()}
                </Heading>
                <Image
                  source={{ uri: iconUri }}
                  w={{ base: '34px', lg: '67px' }}
                  h={{ base: '30px', lg: '40px' }}
                />
              </HStack>
              {!isMobile ? <SubHeading /> : null}
            </Stack>
            <Stack flex={1}>
              <Image
                w="100%"
                h={{ base: '305px', lg: '359px' }}
                source={{ uri: bannerUri }}
                resizeMode="contain"
              />
            </Stack>
            {isMobile ? <SubHeading /> : null}
          </VToHStack>
          <VToHStack
            flex={1}
            alignSelf="center"
            justifyContent="start"
            alignItems={{ base: 'center', lg: 'start' }}
            space={{ base: 6, lg: 12 }}
            h="fit-content"
            flexWrap={{ base: 'nowrap', lg: 'wrap' }}
            mb={4}
            {...props}
          >
            {availableAddons
              ?.filter((recipe) => recipe.type === recipeType)
              .map((recipe, i) => {
                const { unit, description } = ADDONS_CONFIGURATION[recipe.type as AddonsType];
                const quantity = addOnQuantities ? addOnQuantities[recipe.id] : undefined;
                const firstPetWithRecipe = getFirstPetWithAddon(account, recipe.id);
                const onAdd = () => {
                  setModalRecipe(recipe);
                  if (activePets.length > 1) {
                    setIsDogSelectOpen(true);
                  } else if (activePets?.length) {
                    handleAdd(recipe.default_product.code, activePets[0], quantity ? quantity : 0);
                  }
                };
                return (
                  <CarouselItem
                    h="fit-content"
                    unit={unit}
                    key={recipe.id + i.toString()}
                    title={recipe.name}
                    onPressImage={() => {
                      onPressAddOn(recipe);
                    }}
                    description={description.forCarousel}
                    price={recipe.default_product.price.price}
                    quantity={quantity}
                    image={`${images}${capitalize(recipe.type)}/${recipe.id}.png`}
                    isLongPressAvailable
                    hoverStateImage={`${images}${capitalize(recipe.type)}/${recipe.id}-HS.png`}
                    value={0}
                    isLoading={isRecipesLoading || isAddAddonsLoading}
                    badgeMessage={capitalize(recipe.type)}
                    onAdd={onAdd}
                    onEditQuantity={() =>
                      navigation.push('Dogs', { petId: firstPetWithRecipe, scrollToTop: true })
                    }
                  />
                );
              })}
          </VToHStack>
        </Stack>
        {modalRecipe ? (
          <PDPModal
            recipe={modalRecipe}
            buttonText="ADD TO PLAN"
            showCTA
            prices={[modalRecipe.default_product?.price.price]}
            pricingUnits={[modalRecipe.type === RecipeType.SNACK ? 'bag' : 'pack']}
            updateState={() => {
              if (account?.pets.length > 1) {
                setIsDogSelectOpen(true);
              } else {
                handleAdd(
                  modalRecipe.default_product?.code,
                  account?.pets[0].id,
                  addOnQuantities?.[modalRecipe.id] ?? 0
                );
              }
            }}
            isOpen={isPDPOpen}
            onClose={() => setIsPDPOpen(false)}
          />
        ) : null}
        {modalRecipe ? (
          <DogSelectModal
            isOpen={isDogSelectOpen}
            onClose={() => setIsDogSelectOpen(false)}
            onSuccess={() => setIsDogSelectOpen(false)}
            recipe={modalRecipe}
            petIds={activePets}
            onConfirm={addAddon}
            isLoading={isRecipesLoading || isAddAddonsLoading}
            showQuantityByPet
          />
        ) : null}
      </VStack>
    );
  }
);
