import {
  Badge,
  Box,
  Button,
  CloseIcon,
  Divider,
  Heading,
  HStack,
  IconButton,
  IModalProps,
  Modal,
  ScrollView,
  Spacer,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { useRef } from 'react';

import { Pricing } from './Pricing';
import { getAccordionInfo } from './getAccordionInfo';

import { Recipe, RecipeType } from '@/api/types/accountServices';
import { Accordion, Image, ResponsiveCarousel, ReviewCarousel } from '@/components/Elements';
import { ADDONS_CONFIGURATION, AddonsType, ASSETS_URL } from '@/constants';
import { useBodyScrollLock } from '@/hooks';
import { capitalize } from '@/utils';

export interface PDPModalProps extends IModalProps {
  recipe: Recipe;
  prices?: (string | null)[];
  discountedPrices?: (string | null)[];
  pricingUnits?: string[];
  isTrial?: boolean;
  updateState?: () => void;
  buttonText?: string;
  showCTA?: boolean;
}

// We should consider using backend DB for these images...
function getPDPHeroImages(recipeType: RecipeType, id: string) {
  return Array(5)
    .fill('')
    .map((_, i) => `${ASSETS_URL}/pictos/PDP/${capitalize(recipeType)}/${id}/${i}.png`);
}

export const PDPModal = ({
  recipe,
  prices = [],
  discountedPrices = [],
  pricingUnits = [],
  isTrial = false,
  updateState,
  buttonText,
  showCTA = false, // PDP modal CTAs are being hidden for now -- PRODUCT-1768
  ...props
}: PDPModalProps) => {
  const { id, name, type, info_pills, description, reviews } = recipe;
  const accordionInfo = getAccordionInfo(recipe);

  const initialRef = useRef(null);
  const reviewsWithRecipe = reviews
    .map((review) => ({
      ...review,
      recipe: { id, name, type },
    }))
    .sort((review1, review2) => review1.seq_no - review2.seq_no);

  const lockRef = useBodyScrollLock({ enabled: props.isOpen });

  const heroImages = getPDPHeroImages(type, id);
  const addonRecipeSubtitle: string | undefined =
    ADDONS_CONFIGURATION[type as AddonsType]?.subtitle[id];

  return (
    <Modal size="full" initialFocusRef={initialRef} useRNModal focusable {...props}>
      <Modal.Content
        bg="white"
        px={2}
        my={{ base: '5vh', lg: 4 }}
        minHeight={{ base: '85vh', lg: '95vh' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        w={{ base: '95vw', lg: '820px' }}
        borderWidth="2px"
      >
        <ScrollView ref={lockRef}>
          <IconButton
            position="sticky"
            top={4}
            right={8}
            zIndex={1000}
            alignSelf="flex-end"
            p="0px"
            onPress={() => props.onClose?.()}
            icon={
              <CloseIcon
                alt="Close product detail page"
                color="black"
                size={{ base: '11px', md: '14px' }}
              />
            }
          />
          <VStack
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            w={{ base: '95vw', lg: '820px' }}
            p={{ base: 4, lg: 6 }}
            h="100%"
          >
            <ResponsiveCarousel
              data={heroImages}
              width={{ base: 264, md: 460 }}
              height={{ base: 264, md: 460 }}
              renderItem={({ item }) => {
                return (
                  <VStack
                    width={{ base: 264, md: 460 }}
                    height={{ base: 264, md: 460 }}
                    overflow="hidden"
                  >
                    <Image
                      source={{ uri: item }}
                      alt={name + ' detail image'}
                      width="100%"
                      height="100%"
                      resizeMode="cover"
                    />
                  </VStack>
                );
              }}
            />
            <Spacer size={{ base: 4, lg: 6 }} />
            <Heading size={{ base: 'bodyLg', lg: 'titleSm' }} fontWeight="bold">
              {name}
            </Heading>
            <Spacer size={{ base: 1, lg: 2 }} />
            <Stack space={1} alignItems="center">
              {prices?.map((price, i) => (
                <Pricing
                  recipeType={type}
                  pricing={Number(price)}
                  pricingUnit={pricingUnits[i] || 'meal'}
                  isTrial={isTrial && i === 0}
                  discountedPrice={
                    i < discountedPrices.length && discountedPrices[i] !== null
                      ? Number(discountedPrices[i])
                      : null
                  }
                />
              )) || null}
            </Stack>
            {addonRecipeSubtitle ? (
              <Stack>
                <Text fontWeight="medium" fontSize="textSmToMd" fontFamily="secondary">
                  {addonRecipeSubtitle}
                </Text>
              </Stack>
            ) : null}
            <Spacer size={{ base: 4, lg: 6 }} />
            <Box display="flex" flexDir="row" justifyContent="center" alignItems="center">
              {info_pills.length > 0 &&
                info_pills.map((obj, i, arr) => {
                  return (
                    <Box
                      key={obj.slug + i.toString() + 'container'}
                      display="flex"
                      flexDir="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Badge
                        _text={{
                          fontSize: { base: '12px', lg: '14px' },
                          lineHeight: { base: '16px', lg: '18px' },
                        }}
                        key={obj.slug + i.toString()}
                        variant="smallInfoPill"
                      >
                        {obj.text}
                      </Badge>
                      {i < arr.length - 1 ? <Spacer size={{ base: 1, lg: 2 }} /> : null}
                    </Box>
                  );
                })}
            </Box>
            <Spacer size={{ base: 4, lg: 6 }} />
            <Box w="100%" px={{ base: '0px', lg: '48px' }}>
              <Divider bg="gallery" />
              <Spacer size={{ base: 4, lg: 6 }} />
              {/* *************** OVERVIEW ********************* */}
              <Box>
                <Heading size="bodySmToMd" fontWeight="bold" textAlign="left" ref={initialRef}>
                  Overview
                </Heading>
                <Spacer size={{ base: 2, lg: 4 }} />
                <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
                  {description}
                </Text>
              </Box>
              <Spacer size={{ base: 4, lg: 6 }} />
              <Divider bg="gallery" />
              {/* *************** ACCORDION ********************* */}
              <Accordion items={accordionInfo} />
              <Divider bg="gallery" />
            </Box>
            {/* *************** REVIEWS ********************* */}
            {reviews && reviews.length > 0 && (
              <VStack justifyContent="center" alignItems="center" my={{ base: 4, lg: 6 }} space={5}>
                <Heading size="bodyMlToTitleMd" textAlign="center" fontWeight="bold">
                  Reviews
                </Heading>
                <ReviewCarousel data={reviewsWithRecipe} width={{ base: 311, md: 400 }} />
              </VStack>
            )}
          </VStack>
          {showCTA ? (
            <HStack
              justifyContent="center"
              alignItems="center"
              alignSelf="center"
              w="100%"
              position="sticky"
              px={4}
              pt={6}
              pb={{ base: 4, lg: 6 }}
              bottom={0}
              h="fit-content"
              bg="white"
              style={{
                shadowColor: 'rgba(0,0,0,0.15)',
                shadowOffset: { width: -4, height: -4 },
                shadowRadius: 4,
              }}
            >
              <Button
                w={{ base: '311px', lg: '290px' }}
                borderWidth={{ base: '2px', lg: '3px' }}
                onPress={() => {
                  updateState?.();
                  props.onClose?.();
                }}
                variant="primary"
              >
                {buttonText}
              </Button>
            </HStack>
          ) : null}
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
};
