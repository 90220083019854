import {
  Heading,
  Text,
  Factory,
  Pressable,
  VStack,
  IImageProps,
  Badge,
  ITextProps,
  IHeadingProps,
  Button,
  HStack,
  Stack,
} from 'native-base';
import { useState } from 'react';

import { Image } from '@/components/Elements';
import { roundPriceShort } from '@/utils';

export type CarouselItemProps = {
  title: string;
  description: string;
  image: string;
  value: number;
  primaryCtaText?: string;
  price?: number | string;
  unit?: string;
  quantity?: number | string;
  onAdd?: () => void;
  onRemove?: () => void;
  onPressImage?: () => void;
  onEditQuantity?: () => void;
  isLongPressAvailable?: boolean;
  hoverStateImage?: string;
  isLoading?: boolean;
  plusDisabledMessage?: string;
  badgeMessage?: string;
  imageProps?: IImageProps;
  descriptionProps?: ITextProps;
  titleProps?: IHeadingProps;
};

export const MyCarouselItem = ({
  title,
  description,
  image,
  value,
  primaryCtaText = 'ADD TO PLAN',
  price,
  unit = 'bag',
  quantity,
  isLongPressAvailable = false,
  hoverStateImage = '',
  isLoading = false,
  plusDisabledMessage,
  badgeMessage,
  onAdd,
  onRemove = () => {},
  onPressImage,
  onEditQuantity,
  imageProps,
  descriptionProps,
  titleProps,
  ...props
}: CarouselItemProps) => {
  const [showHoverStateImage, setShowHoverStateImage] = useState(false);

  const toggleHoverImage = () =>
    isLongPressAvailable ? setShowHoverStateImage((prev) => !prev) : null;

  const quantityTextStyle = {
    fontFamily: 'secondary',
    size: 'bodySmToMd',
    color: 'sntGrey.primary',
    fontWeight: 'bold',
  };

  return (
    <VStack
      justifyContent="start"
      alignItems="center"
      h="100%"
      space={{ base: '8px', lg: '16px' }}
      maxW={{ lg: '265px' }}
      {...props}
    >
      <Pressable
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderWidth="0px"
        _hover={{ borderWidth: '0px' }}
        p="0px"
        h="fit-content"
        onPress={() => onPressImage?.()}
        onLongPress={toggleHoverImage}
        onHoverIn={toggleHoverImage}
        onHoverOut={toggleHoverImage}
      >
        {badgeMessage ? (
          <Badge
            variant="smallTabActive"
            pointerEvents="none"
            position="absolute"
            zIndex={1}
            top={2}
            right={2}
            px={1}
          >
            {badgeMessage}
          </Badge>
        ) : null}
        <Image
          alt={title}
          size="265px"
          source={{ uri: isLongPressAvailable && showHoverStateImage ? hoverStateImage : image }}
          resizeMode="contain"
          bg="sntGrey.athens"
          {...imageProps}
        />
      </Pressable>
      <VStack justifyContent="center" alignItems="center" w="100%" space={2}>
        <Heading size="bodyLg" fontWeight="bold" textAlign="center" {...titleProps}>
          {title}
        </Heading>

        {price && unit ? (
          <Text size="bodyMd" fontWeight="medium" color="black">
            ${roundPriceShort(price)}/{unit}
          </Text>
        ) : null}

        {onAdd ? (
          <Stack px={{ lg: 4 }} w="100%">
            <Button variant="primary" px={0} w="100%" onPress={onAdd} isLoading={isLoading}>
              {primaryCtaText}
            </Button>
          </Stack>
        ) : null}

        {quantity && Number(quantity) > 0 ? (
          <HStack>
            <Text {...quantityTextStyle}>{quantity} added</Text>
            {onEditQuantity ? (
              <Text {...quantityTextStyle}>
                {' '}
                -{' '}
                <Button
                  _text={{ fontFamily: 'secondary', size: 'bodySmToMd', color: 'sntGrey.primary' }}
                  variant="underlineMini"
                  onPress={onEditQuantity}
                >
                  Edit
                </Button>
              </Text>
            ) : null}
          </HStack>
        ) : null}
      </VStack>
    </VStack>
  );
};

export const CarouselItem = Factory(MyCarouselItem);
