import { useFocusEffect } from '@react-navigation/native';
import { AxiosError } from 'axios';
import * as Linking from 'expo-linking';
import { FormControl, Heading, Input, Stack, Button, Text } from 'native-base';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { SessionModal } from './SessionModal';
import { useNavigateToLatestQuestion } from './navigateToLatestQuestion';

import { AnimatedBlock } from '@/components/Elements';
import { FunnelWrapper } from '@/components/layouts';
import { FunnelScreenNames } from '@/constants';
import { identifyDatadogFunnelUser } from '@/datadog';
import { useIsMobile, useUTT } from '@/hooks';
import { useFunnelErrorHandler } from '@/hooks/useFunnelErrorHandler';
import { useNextFunnelStep } from '@/hooks/useNextFunnelStep';
import { useSessionQuery } from '@/hooks/useSessionQuery';
import { queryClient } from '@/lib/react-query';
import { NameAndEmailResolver } from '@/screens';
import segment from '@/segment';
import { FunnelScreenProps } from '@/types';
import { FunnelSession } from '@/types/FunnelSession';

const defaultValues = {
  first_name: '',
  email: '',
};

type TInputData = {
  first_name: string;
  email: string;
};

export default function NameAndEmail({ navigation }: FunnelScreenProps<'NameAndEmail'>) {
  const nextStep = useNextFunnelStep(FunnelScreenNames.NAME_AND_EMAIL);
  const { session, getOrCreateSessionByEmail, mutateClearSessionAnswers, applyDiscounts } =
    useSessionQuery();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: NameAndEmailResolver, mode: 'onBlur', defaultValues });
  const { UTTidentify } = useUTT();
  const [isSessionModalOpen, setIsSessionModalOpen] = useState<boolean>(false);
  const [isUserExistsModalOpen, setIsUserExistsModalOpen] = useState<boolean>(false);
  const [discountCodes, setDiscountCodes] = useState<string | null>();
  const [showEmailBlock, setShowEmailBlock] = useState(false);
  const [showGreetingsBlock, setShowGreetingsBlock] = useState(false);
  const isMobile = useIsMobile();
  const nameInputRef = useRef<HTMLInputElement>();
  const emailInputRef = useRef<HTMLInputElement>();
  const firstNameProvided = !!watch('first_name');
  const navigateToLatestQuestion = useNavigateToLatestQuestion();
  const funnelErrorHandler = useFunnelErrorHandler();

  const identifyAnalytics = (email: string, sessionId: string, firstName: string) => {
    UTTidentify(sessionId, email);
    window.analytics?.identify(sessionId, {
      email,
    });
    segment.trackEvent('Identify', {
      email,
      first_name: firstName,
    });
    identifyDatadogFunnelUser({
      name: firstName,
      email,
      session_id: sessionId,
    });
  };

  const applyDiscountsToSession = async () => {
    try {
      if (discountCodes) {
        await applyDiscounts(discountCodes.split(','));
      }
    } catch (error) {
      funnelErrorHandler(error, 'Applying discount');
    }
  };

  const onSubmit = async ({ email, first_name }: TInputData) => {
    if (!(email || first_name)) {
      return;
    }

    try {
      await getOrCreateSessionByEmail.mutateAsync({ email, first_name });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 409) {
          const versionOfWeb = error.response.data === 'ACTIVE_ACCOUNT' ? '2.0' : '1.0';
          navigation.navigate(FunnelScreenNames.WELCOME_SCREEN, {
            welcomeParams: { first_name, versionOfWeb },
          });
        } else if (error?.response?.data?.message === 'Enter a valid email address.') {
          setError('email', {
            type: 'manual',
            message: 'Enter a valid email address.',
          });
          return;
        } else {
          funnelErrorHandler(error, 'Verify email');
        }
      }
      funnelErrorHandler(error, 'Get or create session by email');
      return;
    }

    const sessionMutateResult = queryClient.getQueryData(['session']) as FunnelSession;
    const session_pet_data = sessionMutateResult.pets ?? {};

    if (Object.keys(session_pet_data).length) {
      return setIsSessionModalOpen(true);
    }

    await applyDiscountsToSession();
    identifyAnalytics(email, sessionMutateResult.id, first_name);
    return navigation.navigate(nextStep);
  };

  const handleNavigateToPortal = () => {
    const redirect_uri = Linking.createURL('/account');
    Linking.openURL(redirect_uri);
  };

  /**
   * ### 'Continue Quiz' CTA pressed
   * - Navigates to page corresponding to first empty question
   * in session object
   * - Ensures that the current session_id persists in the local storage
   *
   * @returns void
   */
  const handleContinuePress = async () => {
    const currentSession = queryClient.getQueryData<FunnelSession>(['session']) as FunnelSession;
    if (!currentSession || !currentSession.first) return;
    const sessionMutateResult = queryClient.getQueryData(['session']) as FunnelSession;
    const session_pet_data = sessionMutateResult.pets ?? {};

    if (Object.keys(session_pet_data).length) {
      await applyDiscountsToSession();
      identifyAnalytics(currentSession.email, currentSession.id, currentSession.first);
      setIsSessionModalOpen(false);

      if (!Object.keys(session_pet_data).length) {
        navigation.navigate(nextStep);
      } else {
        navigateToLatestQuestion(currentSession);
        segment.trackEvent('Funnel Continue Session', {
          session_id: currentSession.id,
          email: currentSession.email,
        });
      }
    }
  };
  /**
   * ### 'Retake Quiz' CTA pressed
   *
   * - Makes call to API to update session with
   *   object only containing first_name
   * - Ensures that the current session_id persists in the local storage
   *
   * @returns void
   */
  const handleRetakePress = async () => {
    const currentSession = queryClient.getQueryData<FunnelSession>(['session']);
    if (!currentSession || !currentSession.first) return;

    await mutateClearSessionAnswers();
    await applyDiscountsToSession();

    setIsSessionModalOpen(false);
    identifyAnalytics(currentSession.email, currentSession.id, currentSession.first);
    return navigation.navigate(nextStep);
  };

  const initialUrl = Linking.useURL();

  useFocusEffect(
    useCallback(() => {
      if (session) {
        reset({
          first_name: session.first,
          email: session.email,
        });
      }
    }, [session])
  );

  useEffect(() => {
    if (initialUrl) {
      const urlObject = new URL(initialUrl);
      const urlDiscountCodes = urlObject.searchParams.get('discount');
      if (urlDiscountCodes) {
        setDiscountCodes(urlDiscountCodes.toUpperCase());
      }
    }
  }, [initialUrl]);

  useEffect(() => {
    if (firstNameProvided) {
      setShowEmailBlock(true);
    } else {
      setShowEmailBlock(false);
    }
  }, [firstNameProvided]);

  return (
    <FunnelWrapper>
      <SessionModal
        isOpen={isSessionModalOpen}
        handleContinuePress={handleContinuePress}
        handleRetakePress={handleRetakePress}
        retakeCta="START OVER"
        onClose={() => setIsSessionModalOpen(false)}
      />
      <SessionModal
        isOpen={isUserExistsModalOpen}
        header="Welcome back!"
        body="It seems like your dog is already being blessed by our food. Please log in."
        handleContinuePress={handleNavigateToPortal}
        continueCta="Log In"
        onClose={() => setIsUserExistsModalOpen(false)}
      />

      <Stack justifyContent="space-between" h={{ base: '100%', md: 25 }}>
        <Stack w="100%">
          <Stack>
            <Heading size="titleSmToMd" fontWeight="bold" textAlign="center">
              Tell us about yourself
            </Heading>
          </Stack>
          <FormControl
            isRequired
            isInvalid={'first_name' in errors}
            mt={{ base: '30px', md: '48px' }}
          >
            <Controller
              control={control}
              name="first_name"
              render={({ field: { onChange, onBlur, value } }) => (
                <Stack
                  w="100%"
                  direction="column"
                  justifyContent="center"
                  alignContent="center"
                  space={{ base: '26px', md: 4 }}
                >
                  <Heading
                    fontWeight="bold"
                    fontFamily="primary"
                    size="bodyMlToTitleSm"
                    alignSelf="center"
                  >
                    What is your first name?
                  </Heading>
                  <Input
                    placeholderTextColor="sntGrey.primary"
                    variant={'first_name' in errors ? 'error' : 'base'}
                    w={{ base: '100%' }}
                    onBlur={() => {
                      onBlur();
                      setShowGreetingsBlock(true);
                    }}
                    onFocus={() => {
                      setShowGreetingsBlock(false);
                    }}
                    onChangeText={onChange}
                    value={value}
                    placeholder="First name"
                    type="text"
                    keyboardType="default"
                    fontFamily="primary"
                    size="bodyMdToLg"
                    autoFocus={!isMobile}
                    ref={nameInputRef}
                    onKeyPress={(event) => {
                      if (event.nativeEvent.key === 'Enter') emailInputRef.current?.focus();
                    }}
                    testID="first-name-input"
                  />
                </Stack>
              )}
            />
            {errors?.first_name?.message && (
              <FormControl.ErrorMessage>{errors.first_name.message}</FormControl.ErrorMessage>
            )}
          </FormControl>
          <AnimatedBlock showBlock={showEmailBlock}>
            <FormControl isRequired isInvalid={'email' in errors}>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Stack w="100%" direction="column" justifyContent="center" alignContent="center">
                    <AnimatedBlock showBlock={showGreetingsBlock}>
                      <Heading
                        fontFamily="secondary"
                        textAlign="center"
                        alignSelf="center"
                        size="bodySmToMd"
                        mt={{ base: '16px', md: '24px' }}
                      >{`Nice to meet you, ${getValues('first_name')}!`}</Heading>
                    </AnimatedBlock>
                    <Heading
                      fontWeight="bold"
                      fontFamily="primary"
                      size="bodyMlToTitleSm"
                      alignSelf="center"
                      mt={{ base: '36px', md: '48px' }}
                      mb={{ base: '16px', md: '24px' }}
                    >
                      What is your email?
                    </Heading>
                    <Stack>
                      <Input
                        variant={'email' in errors ? 'error' : 'base'}
                        w={{ base: '100%' }}
                        onBlur={onBlur}
                        onChangeText={(emailValue) => onChange(emailValue)}
                        value={value}
                        placeholder="Email"
                        type="text"
                        keyboardType="email-address"
                        size="bodyMdToLg"
                        ref={emailInputRef}
                        onKeyPress={(event) => {
                          if (event.nativeEvent.key === 'Enter') {
                            handleSubmit(onSubmit)();
                          }
                        }}
                        testID="email-input"
                      />
                      {errors?.email?.message && (
                        <Text size="bodySm" mt="4px" color="error.default" maxW="521px">
                          {errors.email.message}
                        </Text>
                      )}
                      <Text
                        color="black"
                        fontFamily="secondary"
                        mt={{ base: '16px', md: '24px' }}
                        size="bodySmToMd"
                        textAlign="center"
                      >
                        We ask for your email in case you want to save progress and come back later.
                      </Text>
                    </Stack>
                  </Stack>
                )}
              />
            </FormControl>
          </AnimatedBlock>
        </Stack>
        <Stack w={{ base: '100%' }} mt="48px" pb="24px">
          <Button
            alignSelf="center"
            w="100%"
            maxW={{ base: '100%', md: '290px' }}
            h={{ base: '52px', md: '56px' }}
            size="bodyMdToLg"
            onPress={handleSubmit(onSubmit)}
            isDisabled={!watch('first_name') || !watch('email')}
            isLoading={isSubmitting}
            testID="name-and-email-submit"
          >
            CONTINUE
          </Button>
        </Stack>
      </Stack>
    </FunnelWrapper>
  );
}
